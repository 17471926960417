import "./App.css";
import crossBig from "./assets/cross-big.svg";
import metamask from "./assets/metamask.svg";
import walletConnect from "./assets/walletc.svg";
import coinbaseWallet from "./assets/coibaseWallet.webp";
import Web3 from "web3";
import WalletLink from "walletlink";
import detectEthereumProvider from "@metamask/detect-provider";
import openLink from "./assets/open-link-w.svg";
import {
  getTokensData,
  getMorphysTokensData,
  getMLATokenData,
  isApproveFlowtys,
  isApproveMLA,
  isApproveMorphys,
  rewardsFlowtys,
  balanceFlowtysStakedAll,
  rewardsMLA,
  rewardsMorphys,
  getINKBalance,
  getINKRate,
  ParseFloat,
} from "./model/Blockchain";
import { useEffect, useState, useCallback } from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  Button,
  Alert,
  Modal,
  Dropdown,
} from "react-bootstrap";
import WalletConnectProvider from "@walletconnect/web3-provider";
import * as Sentry from "@sentry/react";
import MobilePlaceholder from "./components/MobilePlaceholder";
import { Route, Link, Redirect } from "react-router-dom";
import PFPPage from "./pages/PFP";
import BannerPage from "./pages/Banner";
import PromoPage from "./pages/Promo";
import StakingPage from "./pages/Staking";
import Shop from "./pages/Shop";

const fetchRetry = require("fetch-retry")(fetch);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [flowtys, setFlowtys] = useState([]);
  const [morphys, setMorphys] = useState([]);
  const [mla, setMLA] = useState([]);
  const [flowtysStaked, setFlowtysStaked] = useState([]);
  const [morphysStaked, setMorphysStaked] = useState([]);
  const [mlaStaked, setMLAStaked] = useState([]);
  const [rewardsToClaim, setRewardsToClaim] = useState(0);
  const [flowtysStakedAll, setFlowtysStakedAll] = useState(0);
  const [inkBalance, setINKBalance] = useState(0);
  const [nftBalance, setNFTBalance] = useState(0);
  const [inkDaily, setINKDaily] = useState(0);
  const [inkDailyAll, setINKDailyAll] = useState(0);

  const [currentAccount, setCurrentAccount] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [modalConnectShow, setModalConnectShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  const [currentProvider, setProvider] = useState();
  const [page, setPage] = useState("staking");
  const [messages, setMessage] = useState([]);
  const [isApprovedFlowtys, setIsApprovedFlowtys] = useState(false);
  const [isApprovedMorphys, setIsApprovedMorphys] = useState(false);
  const [isApprovedMLA, setIsApprovedMLA] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const SignIn = useCallback(async () => {
    setModalConnectShow(true);
  }, []);

  const getFlowtys = async (address) => {
    const params = {
      method: "GET",
      retries: 3,
      crossDomain: true,
      retryDelay: 200,
      // mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      // Make serial requests instead of parallel
      const tokensData = await getTokensData(address);
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const morphysData = await getMorphysTokensData(address);
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const mlaData = await getMLATokenData(address);
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const stakedFlowtys = await balanceFlowtysStakedAll();
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const tokensDataStaked = await getTokensData(address, true);
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const morphysDataStaked = await getMorphysTokensData(address, true);
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const mlaDataStaked = await getMLATokenData(address, true);
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const isApprovedFlowtys = await isApproveFlowtys(address, () => { });
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const isApprovedMorphys = await isApproveMorphys(address, () => { });
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const isApprovedMLA = await isApproveMLA(address, () => { });
      setNFTBalance(
        tokensData.length +
        morphysData.length +
        mlaData.length +
        stakedFlowtys.length +
        tokensDataStaked.length +
        morphysDataStaked.length +
        mlaDataStaked.length
      );
      setIsApprovedFlowtys(isApprovedFlowtys);
      setIsApprovedMorphys(isApprovedMorphys);
      setIsApprovedMLA(isApprovedMLA);
      if (
        tokensDataStaked.length ||
        morphysDataStaked.length ||
        mlaDataStaked.length
      ) {
        const [_rewardsFlowtys, _rewardsMorphys, _rewardsMLA] =
          await Promise.all([
            rewardsFlowtys(
              address,
              tokensDataStaked.map((el) => el.tokenId)
            ),
            rewardsMorphys(
              address,
              morphysDataStaked.map((el) => el.id)
            ),
            rewardsMLA(
              address,
              mlaDataStaked.map((el) => el.id)
            ),
          ]);
        setRewardsToClaim(
          ParseFloat(_rewardsFlowtys + _rewardsMorphys + _rewardsMLA, 2)
        );
      }
      setFlowtysStakedAll(stakedFlowtys);
      const _inkBalance = await getINKBalance(address);
      setINKBalance(ParseFloat(_inkBalance, 2));
      const [inkRate, inkRatePossible] = await Promise.all([
        getINKRate(tokensDataStaked, morphysDataStaked, mlaDataStaked),
        getINKRate(tokensData, morphysData, mlaData),
      ]);
      setINKDaily(inkRate);
      setINKDailyAll(inkRatePossible);
      try {
        const allMeta = await Promise.all(
          tokensData.map(async (tokenData) => {
            const result = await fetchRetry(
              `https://studio.flowtys.com/metadata/${tokenData.tokenId}/0`,
              params
            );
            const metadata = await result.json();
            return {
              ...tokenData,
              ...metadata,
            };
          })
        );
        setFlowtys(allMeta);
      } catch (err) {
        setFlowtys([]);
      }
      try {
        const allMetaMorphys = await Promise.all(
          morphysData.map(async (tokenData) => {
            const result = await fetchRetry(
              `https://studio.flowtys.com/metadata/${tokenData.id}/0`,
              params
            );
            const metadata = await result.json();
            return {
              ...tokenData,
              ...metadata,
            };
          })
        );
        setMorphys(
          allMetaMorphys.map((el) => {
            return {
              ...el,
              name: `${el.isHalloween ? "🎃" : "🎅"} ${el.name.replace(
                "Flowtys",
                ""
              )}`,
            };
          })
        );
      } catch (err) {
        setMorphys([]);
      }
      try {
        const allMetaMLA = await Promise.all(
          mlaData.map(async (tokenData) => {
            const result = await fetchRetry(
              `https://studio.flowtys.com/metadata_mla/${tokenData.id}`,
              params
            );
            const metadata = await result.json();
            return {
              ...tokenData,
              ...metadata,
            };
          })
        );
        setMLA(allMetaMLA);
      } catch (err) {
        setMLA([]);
      }
      try {
        const allMetaStaked = await Promise.all(
          tokensDataStaked.map(async (tokenData) => {
            const result = await fetchRetry(
              `https://studio.flowtys.com/metadata/${tokenData.tokenId}/0`,
              params
            );
            const metadata = await result.json();
            return {
              ...tokenData,
              ...metadata,
            };
          })
        );
        setFlowtysStaked(allMetaStaked);
      } catch (err) {
        setFlowtysStaked([]);
      }
      try {
        const allMetaMorphysStaked = await Promise.all(
          morphysDataStaked.map(async (tokenData) => {
            const result = await fetchRetry(
              `https://studio.flowtys.com/metadata/${tokenData.id}/0`,
              params
            );
            const metadata = await result.json();
            return {
              ...tokenData,
              ...metadata,
            };
          })
        );
        setMorphysStaked(
          allMetaMorphysStaked.map((el) => {
            return {
              ...el,
              name: `${el.isHalloween ? "🎃" : "🎅"} ${el.name.replace(
                "Flowtys",
                ""
              )}`,
            };
          })
        );
      } catch (err) {
        setMorphysStaked([]);
      }
      try {
        const allMetaMLAStaked = await Promise.all(
          mlaDataStaked.map(async (tokenData) => {
            const result = await fetchRetry(
              `https://studio.flowtys.com/metadata_mla/${tokenData.id}`,
              params
            );
            const metadata = await result.json();
            return {
              ...tokenData,
              ...metadata,
            };
          })
        );
        setMLAStaked(allMetaMLAStaked);
      } catch (err) {
        setMLAStaked([]);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  const didStakeChange = (_flowtys, _morphys, _mla) => {
    if (_flowtys) {
      setFlowtys(
        flowtys.filter(
          (el) => !_flowtys.find((_el) => el.tokenId === _el.tokenId)
        )
      );
      setFlowtysStaked(
        flowtysStaked.concat(
          flowtys.filter((el) =>
            _flowtys.find((_el) => el.tokenId === _el.tokenId)
          )
        )
      );
    }
    if (_morphys) {
      setMorphys(
        morphys.filter((el) => !_morphys.find((_el) => el.id === _el.id))
      );
      setMorphysStaked(
        morphysStaked.concat(
          morphys.filter((el) => _morphys.find((_el) => el.id === _el.id))
        )
      );
    }
    if (_mla) {
      setMLA(mla.filter((el) => !_mla.find((_el) => el.id === _el.id)));
      setMLAStaked(
        mlaStaked.concat(
          mla.filter((el) => _mla.find((_el) => el.id === _el.id))
        )
      );
    }

    // Promise.all([
    //   getINKRate(flowtysStaked.concat(flowtys.filter(el => _flowtys.find(_el => el.tokenId === _el.tokenId))), morphysStaked.concat(morphys.filter(el => _morphys.find(_el => el.id === _el.id))), mlaStaked.concat(mla.filter(el => _mla.find(_el => el.id === _el.id)))),
    //   getINKRate(flowtys.filter(el => !_flowtys.find(_el => el.tokenId === _el.tokenId)), morphys.filter(el => !_morphys.find(_el => el.id === _el.id)), mla.filter(el => !_mla.find(_el => el.id === _el.id)))]).then((inkRate, inkRatePossible) => {
    //     setINKDaily(inkRate);
    //     setINKDailyAll(inkRatePossible);
    // });
  };

  const didUnStakeChange = (_flowtys, _morphys, _mla) => {
    const _flowtysStaked_ = flowtysStaked.filter(
      (el) => !_flowtys.find((_el) => el.tokenId === _el.tokenId)
    );
    const _flowtys_ = flowtys.concat(
      flowtysStaked.filter((el) =>
        _flowtys.find((_el) => el.tokenId === _el.tokenId)
      )
    );

    if (_flowtys) {
      setFlowtysStaked(
        flowtysStaked.filter(
          (el) => !_flowtys.find((_el) => el.tokenId === _el.tokenId)
        )
      );
      setFlowtys(
        flowtys.concat(
          flowtysStaked.filter((el) =>
            _flowtys.find((_el) => el.tokenId === _el.tokenId)
          )
        )
      );
    }
    const _morphysStaked_ = morphysStaked.filter(
      (el) => !_morphys.find((_el) => el.id === _el.id)
    );
    const _morphys_ = morphys.concat(
      morphysStaked.filter((el) => _morphys.find((_el) => el.id === _el.id))
    );
    if (_morphys) {
      setMorphysStaked(
        morphysStaked.filter((el) => !_morphys.find((_el) => el.id === _el.id))
      );
      setMorphys(
        morphys.concat(
          morphysStaked.filter((el) => _morphys.find((_el) => el.id === _el.id))
        )
      );
    }
    const _mlaStaked_ = mlaStaked.filter(
      (el) => !_mla.find((_el) => el.id === _el.id)
    );
    const _mla_ = mla.concat(
      mlaStaked.filter((el) => _mla.find((_el) => el.id === _el.id))
    );
    if (_mla) {
      setMLAStaked(
        mlaStaked.filter((el) => !_mla.find((_el) => el.id === _el.id))
      );
      setMLA(
        mla.concat(
          mlaStaked.filter((el) => _mla.find((_el) => el.id === _el.id))
        )
      );
    }

    // Promise.all([
    //   getINKRate(_flowtysStaked_,
    //     _morphysStaked_, _mlaStaked_),
    //   getINKRate(_flowtys_,
    //     _morphys_, _mla_)]).then((inkRate, inkRatePossible) => {
    //     setINKDaily(inkRate);
    //     setINKDailyAll(inkRatePossible);
    // });
  };

  const connectEth = async (provider, isMetamask) => {
    try {
      const web3 = new Web3(provider);
      if (!provider) {
        setMessage([
          {
            head: "Wallet not found",
            body: `Please install the MetaMask!`,
            variant: "warning",
          },
        ]);
      } else {
        const address = await ConnectWallet(web3, isMetamask);
        if (!!address) {
          setMessage((messages) => [
            ...messages.filter((el) => el.body !== `Address: ${address}`),
            {
              head: "👋 You are logged in",
              body: `Address: ${address}`,
              variant: "success",
            },
          ]);
          await getFlowtys(address);
          window.ethereum.on("accountsChanged", handleAccountsChanged);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const connectMetamask = async () => {
    setModalConnectShow(false);
    const provider = await detectEthereumProvider();
    setProvider(provider);
    await connectEth(provider, true);
  };

  const connectWalletConnect = async () => {
    setModalConnectShow(false);
    const provider = new WalletConnectProvider({
      infuraId: process.env.REACT_APP_INFURA_APP_ID,
    });
    try {
      await provider.enable();
      setProvider(provider);
      await connectEth(provider, false);
    } catch (e) {
      console.error(e);
    }
  };

  const connectWalletLink = async () => {
    setModalConnectShow(false);
    const walletLink = new WalletLink({
      appName: "Flowtys Studio",
      appLogoUrl: "/favicon.ico",
      darkMode: true,
    });

    // Initialize a Web3 Provider object
    const provider = walletLink.makeWeb3Provider(
      `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_APP_ID}`,
      1
    );

    try {
      setProvider(provider);
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      await getFlowtys(accounts[0]);
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    } catch (e) {
      console.error(e);
    }
  };

  const ConnectWallet = async (web3, isMetamask) => {
    try {
      const accounts = isMetamask
        ? await window.ethereum.request({ method: "eth_requestAccounts" })
        : await web3.eth.getAccounts();
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      return accounts[0];
    } catch (err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.info("Please connect to MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Rejected Request",
            body: "Please connect to a MetaMask.",
            variant: "info",
          },
        ]);
      } else if (err.code === -32002) {
        console.info("Please unlock MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Request Pending",
            body: "Please unlock a MetaMask and try agin.",
            variant: "info",
          },
        ]);
      } else {
        console.error(err);
        setMessage((messages) => [
          ...messages,
          { head: "Error", body: err.message, variant: "info" },
        ]);
      }
    }
  };

  const handleAccountsChanged = async (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setMessage((messages) => [
        ...messages,
        {
          head: "User Rejected Request",
          body: "Please connect to MetaMask.",
          variant: "info",
        },
      ]);
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0]);
      await getFlowtys(accounts[0]);
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/staking") {
      setPage("staking");
    } else if (window.location.pathname === "/pfp") {
      setPage("pfp");
    } else if (window.location.pathname === "/banner") {
      setPage("banner");
    } else if (window.location.pathname === "/promo") {
      setPage("promo");
    }
    window.addEventListener("resize", handleWindowSizeChange);
    window.onbeforeunload = function () {
      return "Prevent reload";
    };
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const SignOut = async () => {
    setIsLogged(false);
    setCurrentAccount("");
    try {
      await currentProvider.disconnect();
    } catch { }
  };

  const shortAddr = () => {
    return `${currentAccount.substr(0, 4)}...${currentAccount.substring(
      currentAccount.length - 4,
      currentAccount.length
    )}`;
  };

  const Message = (props) => {
    const [show, setShow] = useState(true);

    const close = () => {
      setShow(false);
      setMessage(messages.filter((item, index) => index !== props.id));
    };

    useEffect(() => {
      // when the component is mounted, the alert is displayed for 3 seconds
      // if (!props.nohide) {
      //   setTimeout(() => {
      //     close();
      //   }, 3000);
      // }
    }, []);

    if (show) {
      return (
        <Alert className="flowty-alert" onClose={close} dismissible>
          <Alert.Heading>{props.head}</Alert.Heading>
          <p className="alert-flowty">
            {props.body}{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={props.url}
              role="button"
              tabIndex="0"
              className="alert-link-flowty"
            >
              {props.urlText1}{" "}
              <img
                alt=""
                hidden={!!!props.urlText1}
                style={{ transition: "none" }}
                src={openLink}
                className="external-link"
              />
            </a>
          </p>
          <hr hidden={!!!props.info1} />
          <p hidden={!!!props.info1} className="mb-0 flowty-alert-sub">
            {props.info1}{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={props.url2}
              role="button"
              tabIndex="0"
              className="alert-link-flowty"
            >
              {props.urlText2}{" "}
              <img
                alt=""
                style={{ transition: "none" }}
                src={openLink}
                className="external-link"
              />
            </a>
          </p>
          <p hidden={!!!props.info2} className="mb-0 flowty-alert-sub">
            {props.info2}
          </p>
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  function ConectModal(props) {
    return (
      <Modal
        {...props}
        className="align-items-center justify-content-center"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="trusted-modal"
        centered
      >
        <Modal.Body>
          <Row
            className="align-items-center justify-content-center h-100 row-border wallet-connect-hover"
            onClick={connectMetamask}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={metamask}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">MetaMask</div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect to your MetaMask Wallet
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover row-border"
            onClick={connectWalletConnect}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={walletConnect}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Wallet Connect
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Scan with WalletConnect to connect
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover"
            onClick={connectWalletLink}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={coinbaseWallet}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Coinbase Wallet
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect your browser or mobile app
                </div>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {/* <div hidden={!isMobile}>
        <MobilePlaceholder />
      </div> */}
      <div>
        {isMobile && (
          <Navbar className="navbar-expand-md" variant="dark">
            <div className="top-connect-box">
              <Row>
                <Col>
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/f13776_d11ccab03ede4e638b2bbbaf6bf335ac~mv2.png/v1/fill/w_300,h_100,al_c,q_85,usm_0.66_1.00_0.01/f13776_d11ccab03ede4e638b2bbbaf6bf335ac~mv2.webp"
                    width="150"
                    height="50"
                    className="d-inline-block align-top"
                  />{" "}
                </Col>
                <Col className="justify-content-center align-items-center text-center">
                  <div className="studio-title">STUDIO</div>
                </Col>
              </Row>
            </div>
            <Row className="mx-auto order-0 justify-content-center align-items-center ">
              <Col
                className="justify-content-center align-items-center "
                md="auto"
              >
                {" "}
                <Button
                  disabled={page === "gif"}
                  className="menu-button"
                  onClick={() => {
                    setPage("gif");
                  }}
                >
                  <Link to="/shop">$INK Shop</Link>
                </Button>{" "}
              </Col>
            </Row>
            <div className="float-right top-connect-box">
              <Row>
                <Col className="mr-3">
                  <div className="balance-top">
                    {nftBalance} NFTs / {inkBalance} $INK
                  </div>
                </Col>

                <Col>
                  <img
                    alt=""
                    hidden={!isLogged}
                    src={crossBig}
                    className="Cross-big-top"
                    onClick={SignOut}
                  />
                </Col>
              </Row>
            </div>
          </Navbar>
        )}
        {!isMobile && (
          <Navbar className="navbar-expand-md" variant="dark">
            <div className="top-connect-box">
              <Row>
                <Col>
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/f13776_d11ccab03ede4e638b2bbbaf6bf335ac~mv2.png/v1/fill/w_300,h_100,al_c,q_85,usm_0.66_1.00_0.01/f13776_d11ccab03ede4e638b2bbbaf6bf335ac~mv2.webp"
                    width="150"
                    height="50"
                    className="d-inline-block align-top"
                  />{" "}
                </Col>
                <Col className="justify-content-center align-items-center text-center">
                  <div className="studio-title">STUDIO</div>
                </Col>
              </Row>
            </div>
            <Row className="mx-auto order-0 justify-content-center align-items-center ">
              <Col className="justify-content-center align-items-center ">
                {" "}
                <Button
                  disabled={page === "staking"}
                  className="menu-button"
                  onClick={() => {
                    setPage("staking");
                  }}
                >
                  <Link to="/staking">Staking</Link>
                </Button>{" "}
              </Col>
              <Col className="justify-content-center align-items-center ">
                {" "}
                <Dropdown>
                  <Dropdown.Toggle className="menu-button" id="dropdown-basic">
                    EDIT
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link to="/pfp" className="dropdown-item">
                      PFP
                    </Link>
                    <Link to="/banner" className="dropdown-item">
                      Banner
                    </Link>

                    <Link to="/promo" className="dropdown-item">
                      GIF
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col
                className="justify-content-center align-items-center "
                md="auto"
              >
                {" "}
                <Button
                  disabled={page === "gif"}
                  className="menu-button"
                  onClick={() => {
                    setPage("gif");
                  }}
                >
                  <Link to="/shop">$INK Shop</Link>
                </Button>{" "}
              </Col>
            </Row>
            <div className="float-right top-connect-box">
              <Row>
                <Col className="mr-3">
                  <div className="balance-top">
                    {nftBalance} NFTs / {inkBalance} $INK
                  </div>
                </Col>
                <Col>
                  <Button
                    className="connect-top"
                    disabled={isLogged}
                    onClick={SignIn}
                  >
                    {isLogged ? shortAddr() : "Connect"}
                  </Button>{" "}
                </Col>
                <Col>
                  <img
                    alt=""
                    hidden={!isLogged}
                    src={crossBig}
                    className="Cross-big-top"
                    onClick={SignOut}
                  />
                </Col>
              </Row>
            </div>
          </Navbar>
        )}

        <div className="message-list">
          {messages.map((item, i) => (
            <Message
              head={item.head}
              body={item.body}
              variant={item.variant}
              id={i}
              key={i}
              url={item.url}
              url2={item.url2}
              urlText1={item.urlText1}
              urlText2={item.urlText2}
              info1={item.info1}
              info2={item.info2}
              nohide={item.nohide}
            />
          ))}
        </div>
        <ConectModal
          show={modalConnectShow}
          onHide={() => setModalConnectShow(false)}
        />

        <Container fluid>
          <Row hidden={isLogged} className="h-100 z-high">
            <Col className="align-items-center justify-content-center h-100  ">
              <Row md="auto" className="justify-content-center w-100 main-box">
                <Button
                  className="main-button main-connect-button"
                  onClick={SignIn}
                >
                  Connect
                </Button>{" "}
              </Row>
            </Col>
          </Row>
          <div hidden={!isLogged}>
            <Row hidden={!isLoading} className="mt-5 list-flowtys">
              <div className="pfp-modal-title"> Loading...</div>
            </Row>
            <div hidden={isLoading}>
              <Route exact path="/" hidden={isLoading}>
                {isMobile && <Redirect to="/shop" />}
                {!isMobile && <Redirect to="/pfp" />}
              </Route>
              <Route
                path="/staking"
                hidden={isLoading}
                render={() => (
                  <StakingPage
                    web3={new Web3(currentProvider)}
                    hidden={isLoading}
                    address={currentAccount}
                    flowtys={flowtys}
                    morphys={morphys}
                    mla={mla}
                    flowtysStaked={flowtysStaked}
                    morphysStaked={morphysStaked}
                    mlaStaked={mlaStaked}
                    isApprovedFlowtys={isApprovedFlowtys}
                    isApprovedMorphys={isApprovedMorphys}
                    isApprovedMLA={isApprovedMLA}
                    inkBalance={inkBalance}
                    rewardsToClaim={rewardsToClaim}
                    flowtysStakedAll={flowtysStakedAll}
                    inkDaily={inkDaily}
                    inkDailyAll={inkDailyAll}
                    didStake={didStakeChange}
                    didUnstake={didUnStakeChange}
                    onMessage={(head, body) => {
                      setMessage([
                        {
                          head,
                          body,
                          variant: "warning",
                        },
                      ]);
                    }}
                  />
                )}
              />
              <Route
                path="/pfp"
                hidden={isLoading}
                render={() => (
                  <PFPPage flowtys={flowtys} morphys={morphys} mla={mla} />
                )}
              />
              <Route
                path="/banner"
                hidden={isLoading}
                render={() => (
                  <BannerPage flowtys={flowtys} morphys={morphys} mla={mla} />
                )}
              />
              <Route
                path="/promo"
                hidden={isLoading}
                render={() => <PromoPage flowtys={flowtys} />}
              />
              <Route
                path="/shop"
                hidden={isLoading}
                render={() => (
                  <Shop
                    web3={new Web3(currentProvider)}
                    address={currentAccount}
                    inkBalance={inkBalance}
                    flowtyStaked={flowtysStaked.length}
                    flowtyOwned={flowtys.length}
                    isMobile={isMobile}
                    onMorphyMinted={(hash) => {
                      setMessage((messages) => [
                        ...messages,
                        {
                          head: "🎉 Morphys SUPES on the way!",
                          url: "https://etherscan.io/tx/" + hash,
                          url2: "https://opensea.io/account",
                          urlText1: "Etherscan",
                          urlText2: "Opensea Account",
                          body: `You can check the transaction on`,
                          info1: "Your NFT will then appear in your",
                          info2:
                            "This might take a few minutes depending on network load.",
                          nohide: true,
                          variant: "dark",
                        },
                      ]);
                    }}
                  />
                )}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default App;
